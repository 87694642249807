import API from "@/api";

export default {
  table: params => {
    return API.requestGet({
      url: "loan/repayment/list",
      params,
      // auth: true,
    });
  },
  stagTable: params => {
    return API.requestGet({
      url: "loan/repayment/stagings",
      params,
      // auth: true,
    });
  },
  trueStag: params => {
    return API.requestPost({
      url: "loan/repayment/payStaging",
      data: params,
    });
  },
  renew: params => {
    return API.requestPost({
      url: "loan/repayment/renewOrder",
      data: params,
    });
  },
};
