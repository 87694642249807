<template>
  <div>
    <el-dialog
      title="续期操作"
      :visible.sync="isShow"
      :width="width"
      top="25vh"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" label-width="80px" class="form"  :rules="rules">
        <el-form-item label="续期天数" prop="renewDays">
          <el-input v-model.number="form.renewDays" placeholder="请输入续期天数"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const intAndMorezero = (rule, value, callback) => {
  if(value === ""){
    callback(new Error('请输入续期天数'));
  }else{
    if(Number.isInteger(Number(value)) && Number(value) >0){
      callback();
    }else{
      callback(new Error('请输入大于0的整数！'))
    }
  }
}
export default {
  name:"renew",
  props:{
    isShow: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      form:{
        renewDays:"1"
      },
      rules:{
        renewDays:[
          { validator:intAndMorezero, trigger: 'blur' }
        ]
      },
      width: '25%'
    }
  },
  methods:{
    cancel(){
      this.$refs['form'].resetFields();
      this.$emit('closeEdit')
    },
    submit(formName){
      this.$refs[formName].validate(valid => {
        if(valid){
          this.$emit('submitEdit', this.form.renewDays);
          this.$refs['form'].resetFields();
        }else{
          return false;
        }
      })
    }
  },
  created(){
    if(document.documentElement.clientWidth < 480){
      this.width = '90%'
    }
  }
}

</script>

<style lang="scss" scoped>
</style>
